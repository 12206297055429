import React, { useState, useEffect } from "react";
import {
  formatarDataBrasilia,
  formatarDataToUTC,
  normalizeString,
} from "../../../components/Funcoes/";
import {
  adicionarPessoaAutorizada,
  buscarPessoasAutorizadasPorDataUsuario,
  excluirVisitanteAgendado,
} from "../../../api/requisicoes/pessoas";

import "./style.css";
import { useAlert } from "../../../context/AlertContext";
import InfoSemRegistro from "../../../components/InfoSemRegistro";
import { useConfirm } from "../../../context/ConfirmContext";
import EditarVisitante from "./EditarVisitante";

import Loading from "../../../components/Loading";
import { baixarPlanilhaModelo } from "../../../api/requisicoes/planilhas";
import Planilha from "./Planilha";
import Formulario from "./Formulario";

const AutorizacaoEntrada = () => {
  const [usuario, setUsuario] = useState("");

  const [busca, setBusca] = useState("");
  const [visitantes, setVisitantes] = useState([]);
  const [dataFiltro, setDataFiltro] = useState(""); // Estado para a data do filtro

  const [modalVisible, setModalVisible] = useState(false);
  const [visitanteSelecionado, setVisitanteSelecionado] = useState(null);

  const [opcaoSelecionada, setOpcaoSelecionada] = useState("Formulario");

  const { showAlert } = useAlert();
  const { showConfirm } = useConfirm(); // Use o hook useConfirm

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const hoje = new Date().toISOString().split("T")[0];
    setDataFiltro(hoje);
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    setUsuario(userId);

    const fetchPessoasAutorizadas = async () => {
      if (usuario && dataFiltro) {
        setLoading(true);
        try {
          // Adapte a data no formato necessário para a API
          const dados = await buscarPessoasAutorizadasPorDataUsuario(
            dataFiltro,
            usuario
          );
          setVisitantes(dados);
        } catch (error) {
          if (error.response) {
            showAlert(`Erro! ${error.response.data.error}.`, "erro");
          } else {
            showAlert(
              "Erro desconhecido, contate o administrador do sistema!",
              "erro"
            );
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPessoasAutorizadas();
  }, [dataFiltro, usuario, showAlert]);

  const visitantesFiltrados = visitantes.filter((visitante) =>
    normalizeString(visitante.nomeCompleto).includes(normalizeString(busca))
  );

  const handleExcluir = async (id, nomeCompleto) => {
    showConfirm(
      `Deseja excluir o agendamento de ${nomeCompleto}?`,
      async () => {
        try {
          setLoading(true);
          await excluirVisitanteAgendado(id); // Chama a função para excluir o visitante
          showAlert("Visitante excluído com sucesso!", "sucesso");
        } catch (error) {
          if (error.response) {
            showAlert(`Erro! ${error.response.data.error}.`, "erro");
          } else {
            showAlert(
              "Erro desconhecido, contate o administrador do sistema!",
              "erro"
            );
          }
        } finally {
          setLoading(false);
        }
      },
      () => {
        // Ação para o botão "Cancelar", se necessário
      }
    );
  };

  return (
    <div className="container-autorizacao-entrada">
      {loading && <Loading />} {/* Mostra o carregamento quando necessário */}
      <div className="autorizacao-entrada">
        <div className="agenda-visitante-padding">
          <h1>Agendar Visitante</h1>
        </div>
        <div className="menu-agendar-visitante">
          <div
            className={`opcao ${
              opcaoSelecionada === "Formulario" ? "selecionada" : ""
            }`}
            onClick={() => setOpcaoSelecionada("Formulario")}
          >
            Formulário
          </div>
          <div
            className={`opcao ${
              opcaoSelecionada === "Planilha" ? "selecionada" : ""
            }`}
            onClick={() => setOpcaoSelecionada("Planilha")}
          >
            Planilha
          </div>
        </div>
        <div className="corpo-agenda-visitante">
          {opcaoSelecionada === "Formulario" ? <Formulario /> : <Planilha />}
        </div>
      </div>
      <div className="visitantes-autorizados">
        <div className="header-direito">
          <div className="titulo-autorizados">
            <h1>Visitantes Agendados</h1>
          </div>
          <div className="filtrar-container">
            <div className="filtrar-data">
              <input
                type="date"
                id="dataFiltro"
                value={dataFiltro}
                onChange={(e) => setDataFiltro(e.target.value)}
                min={new Date().toISOString().split("T")[0]} // Impede datas anteriores a hoje
              />
            </div>
            <div className="filtrar-visitantes-usuario">
              <input
                type="text"
                placeholder="Buscar por nome..."
                value={busca}
                onChange={(e) => setBusca(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="tabela-visitantes">
          {visitantesFiltrados.length > 0 ? (
            <table className="tabela-visitantesAgendados">
              <thead>
                <tr>
                  <th>Nome Completo</th>
                  <th>Empresa</th>
                  <th>Responsável</th>
                  <th>Data</th>
                  <th>Horário</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {visitantesFiltrados.map((visitante, index) => (
                  <tr key={index}>
                    <td>{visitante.nomeCompleto.toUpperCase()}</td>
                    <td>{visitante.empresa.toUpperCase()}</td>
                    <td>{visitante.responsavel.toUpperCase()}</td>
                    <td>{formatarDataBrasilia(visitante.dataVisita)}</td>
                    <td>{visitante.horarioVisita}</td>
                    <td>
                      <div className="botoes-icones">
                        <button
                          onClick={() => {
                            setVisitanteSelecionado(visitante); // Define o visitante selecionado
                            setModalVisible(true); // Abre o modal
                          }}
                          className="action-button edit-button"
                          title="Editar"
                        >
                          <i className="fas fa-edit"></i>
                        </button>

                        <button
                          onClick={() =>
                            handleExcluir(visitante.id, visitante.nomeCompleto)
                          }
                          className="action-button delete-button"
                          title="Excluir"
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <InfoSemRegistro />
          )}
        </div>
      </div>
      <EditarVisitante
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        visitante={visitanteSelecionado}
      />
    </div>
  );
};

export default AutorizacaoEntrada;
