import React, { useState, useEffect } from "react";
import {
  baixarPlanilhaModelo,
  enviarPlanilha,
} from "../../../../api/requisicoes/planilhas";

import { useAlert } from "../../../../context/AlertContext";

import "./style.css";
import Loading from "../../../../components/Loading";

const Planilha = () => {
  const [arquivoExcel, setArquivoExcel] = useState(null);
  const [usuario, setUsuario] = useState(null);

  const { showAlert } = useAlert();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userID = localStorage.getItem("userId");
    setUsuario(userID);
  }, [usuario]);

  const handleDownload = async () => {
    try {
      await baixarPlanilhaModelo();
    } catch (error) {
      alert("Erro ao baixar a planilha. Tente novamente.");
    }
  };

  const handleSubmit = async () => {
    if (!arquivoExcel) {
      alert("Por favor, anexe um arquivo antes de enviar.");
      return;
    }

    try {
      setLoading(true);
      await enviarPlanilha(arquivoExcel, usuario);
      showAlert("Agendamento enviado com sucesso!", "sucesso");
      setArquivoExcel("");
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false);
      setArquivoExcel("");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setArquivoExcel(file);
    } else {
      setArquivoExcel(null);
    }
  };

  return (
    <div className="botoes-planilha">
      {loading && <Loading />}
      <div className="div-botao-arquivo">
        <button
          id="download-planilha"
          title="Baixar modelo de planilha."
          onClick={handleDownload}
        >
          <i className="fas fa-download"></i>
          Modelo de Planilha
        </button>
      </div>
      <div className="div-botao-arquivo">
        <input
          type="file"
          id="upload-arquivo"
          accept=".xlsx"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <label
          htmlFor="upload-arquivo"
          id="enviar-planilha"
          title="Enviar arquivo ou formulário."
          className="enviar-excel-botao"
        >
          {arquivoExcel ? (
            <>
              <i className="fas fa-check"></i> Arquivo Anexado
            </>
          ) : (
            <>
              <i className="fas fa-file-alt"></i> Anexar Arquivo
            </>
          )}
        </label>
      </div>

      <div></div>

      <div className="botao-inferior-planilha">
        <button id="botao-agendar-formulario" onClick={handleSubmit}>
          Agendar
        </button>
      </div>
    </div>
  );
};

export default Planilha;
