import api from "../api"; // Ajuste conforme o caminho do seu arquivo de configuração do axios

export const baixarPlanilhaModelo = async () => {
  try {
    const response = await api.get("/download-excel", {
      responseType: "blob", // Necessário para receber o arquivo como Blob
    });

    // Criar um URL temporário para o arquivo Blob
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "AgendamentoVisitante.xlsx"); // Nome do arquivo para download
    document.body.appendChild(link);
    link.click();

    // Remover o link temporário
    document.body.removeChild(link);
  } catch (error) {
    console.error("Erro ao baixar a planilha:", error);
    alert("Erro ao tentar fazer o download. Tente novamente.");
  }
};

export const enviarPlanilha = async (arquivo, userId) => {
  try {
    const formData = new FormData();
    formData.append("file", arquivo); // Adiciona o arquivo ao formulário
    formData.append("usuario", userId); // Adiciona o userId ao formulário

    const response = await api.post("/upload-excel", formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Cabeçalho para upload de arquivo
      },
    });

    return response.data; // Retorna a resposta da API
  } catch (error) {
    throw error;
  }
};
