import React, { useState, useEffect } from "react";

import "./style.css";

import { useAlert } from "../../../../context/AlertContext";
import { useConfirm } from "../../../../context/ConfirmContext";

import Loading from "../../../../components/Loading";
import { adicionarPessoaAutorizada } from "../../../../api/requisicoes/pessoas";

const Formulario = () => {
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [responsavel, setResponsavel] = useState("");
  const [dataVisita, setDataVisita] = useState("");
  const [horarioVisita, setHorarioVisita] = useState("");
  const [usuario, setUsuario] = useState("");

  const { showAlert } = useAlert();
  const { showConfirm } = useConfirm(); // Use o hook useConfirm

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    setUsuario(userId);
  }, [usuario, showAlert]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!usuario) {
      showAlert(
        "Usuário não encontrado. Certifique-se de estar autenticado.",
        "erro"
      );
      return;
    }

    const dados = {
      nomeCompleto,
      empresa,
      responsavel,
      dataVisita,
      horarioVisita,
      usuario,
    };

    try {
      setLoading(true);
      await adicionarPessoaAutorizada(dados);
      showAlert("Pessoa autorizada com sucesso!", "sucesso");
      // Limpa os campos do formulário após o envio
      setNomeCompleto("");
      setEmpresa("");
      setResponsavel("");
      setDataVisita("");
      setHorarioVisita("");
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <form className="formulario-autorizacao-entrada" onSubmit={handleSubmit}>
      {loading && <Loading />}
      <div className="campos-formulario-agendar">
        <div className="form-group">
          <label htmlFor="nomeCompleto">Nome Completo:</label>
          <input
            className="input-autorizacao-entrada"
            type="text"
            id="nomeCompleto"
            name="nomeCompleto"
            value={nomeCompleto}
            onChange={(e) => setNomeCompleto(e.target.value)}
            onBlur={(e) => setNomeCompleto(e.target.value.toUpperCase())}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="empresa">Empresa:</label>
          <input
            className="input-autorizacao-entrada"
            type="text"
            id="empresa"
            name="empresa"
            value={empresa}
            onChange={(e) => setEmpresa(e.target.value)}
            onBlur={(e) => setEmpresa(e.target.value.toUpperCase())}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="responsavel">Responsável:</label>
          <input
            className="input-autorizacao-entrada"
            type="text"
            id="responsavel"
            name="responsavel"
            value={responsavel}
            onChange={(e) => setResponsavel(e.target.value)}
            onBlur={(e) => setResponsavel(e.target.value.toUpperCase())}
            required
          />
        </div>
        <div className="data-hora-formulario-entrada">
          <div className="form-group">
            <div id="data-autorizacao-entrada">
              <label htmlFor="data">Data:</label>
              <input
                type="date"
                id="data"
                name="data"
                value={dataVisita}
                onChange={(e) => setDataVisita(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div id="horario-autorizacao-entrada">
              <label htmlFor="horario">Horário:</label>
              <input
                type="time"
                id="horario"
                name="horario"
                value={horarioVisita}
                onChange={(e) => setHorarioVisita(e.target.value)}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className="botao-inferior-formulario">
        <button id="botao-agendar-formulario" type="submit">
          Agendar
        </button>
      </div>
    </form>
  );
};

export default Formulario;
