import api from "../api";

export const buscarTodosUsuarios = async () => {
  try {
    const response = await api.get('/users')

    return response.data
  } catch (error) {
    throw error
  }
};


export const buscarLocaisVinculadosAoUsuario = async (id) => {
  try {
    const response = await api.get(`/userDestination/${id}`)

    return response.data
  } catch (error) {
    throw error
  }
};



export const criarUsuario = async (novoUsuario) => {

  try {
    const response = await api.post('/users/register', {
      cpf: novoUsuario.cpf,
      fullName: novoUsuario.nome,
      username: novoUsuario.usuario,
      password: novoUsuario.senha,
      userType: novoUsuario.privilegios,
      destinationIds: novoUsuario.locaisDestino,
    })

    

    return response.data
  } catch (error) {
    throw error
  }
};


export const editarUsuario = async (id, usuarioEditado) => {

  try {
    const response = await api.put(`user/${id}`, {
      cpf: usuarioEditado.cpf,
      fullName: usuarioEditado.nome,
      username: usuarioEditado.usuario,
      password: usuarioEditado.senha,
      userType: usuarioEditado.privilegios,
      destinationIds: usuarioEditado.locaisDestino
    })

    return response.data
  } catch (error) {
    throw error
  }
};


export const excluirUsuario = async (id) => {
  try {
    const response = await api.delete(`/user/${id}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao excluir usuário:", error);
    throw error;
  }
};

export const editarStatusBloqueio = async (id) => {
  try {
    const response = await api.patch(`/users/${id}/toggle-blocked`);
    return response.data;
  } catch (error) {
    console.error("Erro ao alternar status de bloqueio do usuário:", error.response || error);
    throw error; // Propaga o erro para o chamador tratar
  }
};
