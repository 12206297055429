import React from "react";
import { Link } from "react-router-dom";
import { useConfirm } from "../../context/ConfirmContext";
import "./style.css";

import imagemEntrada from "../../assets/imagem-entrada.webp";
import imagemSaida from "../../assets/imagem-saida.webp";
import imagemCadastrar from "../../assets/identidade-digital.webp";
import imagemAutorizarEntrada from "../../assets/agenda.webp";
import imagemAutorizarSaida from "../../assets/imagem-autorizar-saida.webp";
import imagemCentralControle from "../../assets/central-controle.webp";

const Navbar = () => {
  const userType = Number(localStorage.getItem("userType"));

  // Recuperar e processar o nome
  const fullName = localStorage.getItem("fullName") || "";
  const firstName = fullName.split(" ")[0];

  const { showConfirm } = useConfirm(); // Use o hook useConfirm

  const handleLogout = () => {
    showConfirm(
      "Deseja realmente sair do sistema?",
      () => {
        localStorage.clear();
        window.location.href = "/login";
      },
      () => {
      }
    );
  };

  return (
    <div className="navbar-completa">
      <div className="navbar">
        {/* Links de navegação */}
        {userType === 3 && (
          <>
            <Link to="/central-controle" className="nav-button">
              <img
                src={imagemCentralControle}
                alt="Central de Controle"
                className="nav-icon"
              />
              <span>Central de Controle</span>
            </Link>
          </>
        )}
        {(userType === 1 || userType === 3) && (
          <>
            <Link to="/cadastro-pessoa" className="nav-button">
              <img
                src={imagemCadastrar}
                alt="Cadastrar Pessoa"
                className="nav-icon"
              />
              <span>Cadastrar Pessoa</span>
            </Link>
            <Link to="/registro-entrada" className="nav-button">
              <img
                src={imagemEntrada}
                alt="Registrar Entrada"
                className="nav-icon"
              />
              <span>Registrar Entrada</span>
            </Link>
            <Link to="/registro-saida" className="nav-button">
              <img
                src={imagemSaida}
                alt="Registrar Saída"
                className="nav-icon"
              />
              <span>Registrar Saída</span>
            </Link>
          </>
        )}
        {(userType === 2 || userType === 3 || userType === 4) && (
          <>
            <Link to="/autorizacao-entrada" className="nav-button">
              <img
                src={imagemAutorizarEntrada}
                alt="Autorizar Entrada"
                className="nav-icon"
              />
              <span>Agendar Visitante</span>
            </Link>
            <Link to="/autorizacao-saida" className="nav-button">
              <img
                src={imagemAutorizarSaida}
                alt="Autorizar Saída"
                className="nav-icon"
              />
              <span>Autorizar Saída</span>
            </Link>
          </>
        )}
      </div>
      <div className="nav-welcome">
        Olá, {firstName}
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Navbar;
