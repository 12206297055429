import React, { useState, useEffect } from "react";
import styles from "./ModalCriarUsuario.module.css";
import Loading from "../../../../components/Loading";
import { useAlert } from "../../../../context/AlertContext";
import { criarUsuario, editarUsuario } from "../../../../api/requisicoes/usuarios";
import { listarLocaisDestino } from "../../../../api/requisicoes/variaveis";

const options = [
  { id: 3, label: "ADMINISTRADOR" },
  { id: 4, label: "FATURAMENTO" },
  { id: 2, label: "FUNCIONÁRIO" },
  { id: 1, label: "PORTEIRO" },
];

const ModalCriarUsuario = ({ isOpen, onClose, atualizar, setAtualizar, usuarioEditar, setUsuarioEditar }) => {
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [privilegios, setPrivilegios] = useState({
    privilegioId: "",
    privilegio: "",
  });
  const [localDestino, setLocalDestino] = useState([]);
  const [locaisDestino, setLocaisDestino] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  // Carregar locais de destino
  useEffect(() => {
    const fetchLocaisDestino = async () => {
      try {
        const resposta = await listarLocaisDestino();
        setLocaisDestino(resposta);
      } catch (error) {
        showAlert("Erro ao carregar locais de destino.", "erro");
      }
    };
    fetchLocaisDestino();
  }, []);

  // Preencher os campos no modo de edição
  useEffect(() => {
    if (usuarioEditar) {
      setNome(usuarioEditar.fullName || "");
      setCpf(usuarioEditar.cpf || "");
      setUsuario(usuarioEditar.username || "");
      setSenha(""); // Deixe a senha em branco no modo de edição
      setPrivilegios({
        privilegioId: usuarioEditar.userType?.toString() || "",
        privilegio: options.find((o) => o.id === usuarioEditar.privilegios)?.label || "",
      });
      setLocalDestino(usuarioEditar.locaisDestino || []);
    }
  }, [usuarioEditar]);

  const fecharModal = () => {
      setUsuarioEditar("");
      setNome("");
      setCpf("");
      setUsuario("");
      setSenha("");
      setPrivilegios({ privilegioId: "", privilegio: "" });
      setLocalDestino([]);
    
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const novoUsuario = {
      nome,
      cpf,
      usuario,
      senha,
      privilegios: parseInt(privilegios.privilegioId, 10),
      locaisDestino: localDestino.map((local) => local.id),
    };

    try {
      setLoading(true);
      if (usuarioEditar) {
        // Modo de edição
        await editarUsuario(usuarioEditar.id, novoUsuario);
        showAlert("Usuário atualizado com sucesso!", "sucesso");
      } else {
        // Modo de criação
        await criarUsuario(novoUsuario);
        showAlert("Usuário cadastrado com sucesso!", "sucesso");
      }
      setAtualizar(!atualizar);
      fecharModal();
    } catch (error) {
      if (error.response) {
        showAlert(`Erro! ${error.response.data.error}.`, "erro");
      } else {
        showAlert(
          "Erro desconhecido, contate o administrador do sistema!",
          "erro"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLocalDestinoToggle = (local) => {
    const isSelected = localDestino.some((l) => l.id === local.id);
    if (isSelected) {
      setLocalDestino(localDestino.filter((l) => l.id !== local.id));
    } else {
      setLocalDestino([...localDestino, local]);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modal}>
      {loading && <Loading />}
      <div className={styles.content}>
        <div className={styles.cabecalho}>
          <h2 className={styles.h2}>{usuarioEditar ? "Editar Usuário" : "Criar Usuário"}</h2>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          {/* Formulário */}
          <div className={styles.esquerda}>
            <div>
              <div className={styles.inputNome}>
                <label className={styles.label}>Nome:</label>
                <input
                  className={styles.input}
                  type="text"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  onBlur={(e) => setNome(e.target.value.toUpperCase())}
                  required
                />
              </div>
              <div className={styles.inputCpf}>
                <label className={styles.label}>CPF:</label>
                <input
                  className={styles.input}
                  type="text"
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className={styles.inputUsuario}>
              <label className={styles.label}>Usuário:</label>
              <div>
                <input
                  className={styles.input}
                  type="text"
                  value={usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className={styles.inputSenha}>
              <label className={styles.label}>Senha:</label>
              <div>
                <input
                  className={styles.input}
                  type="text"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  required={!usuarioEditar}
                />
              </div>
            </div>

            <div className={styles.inputPrivilegio}>
              <label className={styles.label}>Privilégios:</label>
              <select
                className={styles.select}
                value={privilegios.privilegioId}
                onChange={(e) =>
                  setPrivilegios({
                    privilegioId: e.target.value,
                    privilegio: e.target.options[e.target.selectedIndex].text,
                  })
                }
                required
              >
                <option value="">Selecione um privilégio</option>
                {options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={styles.inputLocais}>
            <label className={styles.label}>Locais Vinculados:</label>
            <div className={styles["local-destino-container"]}>
              {locaisDestino.map((local) => (
                <div
                  key={local.id}
                  className={`${styles["local-destino-card"]} ${localDestino.some((l) => l.id === local.id)
                    ? styles.selected
                    : ""
                    }`}
                  onClick={() => handleLocalDestinoToggle(local)}
                >
                  <label className={styles.textoCard}>
                    {local.localDestination}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.areaBotoes}>
            <button type="submit" id="modalUsuarios-botao-confirmar">
              Confirmar
            </button>
            <button
              id="modalUsuarios-botao-fechar"
              onClick={() => fecharModal()}
            >
              Fechar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalCriarUsuario;
