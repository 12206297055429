import React, { useEffect, useState } from "react";
import {
  buscarLocaisVinculadosAoUsuario,
  buscarTodosUsuarios,
  editarStatusBloqueio,
  excluirUsuario,
} from "../../../api/requisicoes/usuarios";

import "./style.css";

import ModalCriarUsuario from "./ModalCriarUsuario";

import Loading from "../../../components/Loading";
import ModalEditarUsuario from "./ModalEditarUsuario";

import { useConfirm } from "../../../context/ConfirmContext";
import { useAlert } from "../../../context/AlertContext";

const GestaoUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [senhaVisivel, setSenhaVisivel] = useState(null);
  const [filtro, setFiltro] = useState("");

  const [usuarioSelecionado, setUsuarioSelecionado] = useState({});

  const [atualizar, setAtualizar] = useState(false);

  const [loading, setLoading] = useState(false);
  const { showConfirm } = useConfirm(); // Use o hook useConfirm
  const { showAlert } = useAlert();

  const [modalCriarUsuario, setModalCriarUsuario] = useState(false);
  const [modalEditarUsuario, setModalEditarUsuario] = useState(false);

  // Função para buscar a lista de usuários da API
  const carregarUsuarios = async () => {
    setLoading(true);
    try {
      const data = await buscarTodosUsuarios();
      setUsuarios(data);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
      alert("Erro ao carregar a lista de usuários.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    carregarUsuarios();
  }, [atualizar]);

  const handleCriarUsuario = () => {
    setUsuarioSelecionado("");
    setModalCriarUsuario(true);
  };

  const handleEditarUsuario = async (usuario) => {
    try {
      setLoading(true);
      // Faz a requisição para buscar os locais vinculados ao usuário
      const locaisDestino = await buscarLocaisVinculadosAoUsuario(usuario.id);

      // Define os dados do usuário selecionado junto com os locais vinculados
      setUsuarioSelecionado({
        ...usuario,
        locaisDestino, // Adiciona os locais vinculados ao objeto do usuário
      });

      // Abre o modal para edição
      setModalCriarUsuario(true);
    } catch (error) {
      console.error("Erro ao buscar locais vinculados ao usuário:", error);

      // Exibe um alerta para o usuário em caso de erro
      showAlert(
        "Erro ao carregar os locais vinculados ao usuário. Por favor, tente novamente.",
        "erro"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleExcluirUsuario = async (usuario) => {
    showConfirm(`Deseja excluir o usuário ${usuario.fullName}?`, async () => {
      try {
        setLoading(true);
        await excluirUsuario(usuario.id);
        showAlert("Usuário excluído com sucesso!", "sucesso");
        setAtualizar(!atualizar);
      } catch (error) {
        if (error.response) {
          showAlert(`Erro! ${error.response.data.error}.`, "erro");
        } else {
          showAlert(
            "Erro desconhecido, contate o administrador do sistema!",
            "erro"
          );
        }
      } finally {
        setLoading(false);
      }
    });
  };

  const handleEditarBloqueioUsuario = async (usuario) => {
    const acao = usuario.blocked ? "desbloquear" : "bloquear";
    const acao2 = usuario.blocked ? "desbloqueado" : "bloqueado";
    const sucessoMensagem = `Usuário ${acao2} com sucesso!`;
    const confirmMensagem = `Deseja ${acao} o usuário ${usuario.fullName}?`;

    showConfirm(confirmMensagem, async () => {
      try {
        setLoading(true);
        await editarStatusBloqueio(usuario.id);
        showAlert(sucessoMensagem, "sucesso");
        setAtualizar((prevState) => !prevState); // Atualização segura do estado
      } catch (error) {
        const mensagemErro = error.response?.data?.error
          ? `Erro! ${error.response.data.error}.`
          : "Erro desconhecido, contate o administrador do sistema!";
        showAlert(mensagemErro, "erro");
      } finally {
        setLoading(false);
      }
    });
  };

  const handleMostrarSenha = (id) => {
    setSenhaVisivel(senhaVisivel === id ? null : id);
  };

  // Função para mapear o userType para o texto correspondente
  const getPrivilegios = (userType) => {
    switch (userType) {
      case 1:
        return "Porteiro";
      case 2:
        return "Funcionário";
      case 3:
        return "Administrador";
      case 4:
        return "Faturamento";
      default:
        return "Desconhecido";
    }
  };

  // Função para normalizar texto removendo acentos e caracteres especiais
  const normalizeText = (text) => {
    return text
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  // Filtragem dos usuários com base no filtro de texto
  const usuariosFiltrados = usuarios.filter((usuario) => {
    const fullName = normalizeText(usuario.fullName);
    const cpf = normalizeText(usuario.cpf);
    const search = normalizeText(filtro);
    return fullName.includes(search) || cpf.includes(search);
  });

  return (
    <div className="tela">
      {loading && <Loading />} {/* Mostra o carregamento quando necessário */}
      <div className="autorizacao-saida">
        <div className="header-autorizarSaida">
          <div className="headerEsquerda-autorizarSaida">
            <h1>Lista de Usuários</h1>
          </div>
          <div className="headerDireita-AutorizarSaida">
            <div className="espacobotao-adicionarRegistro">
              <button
                onClick={handleCriarUsuario}
                className="botao-adicionarRegistro-autorizarSaida"
              >
                Criar Usuário
              </button>
            </div>
            <div className="filtrar-nome-autorizarSaida">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Filtrar por nome ou CPF"
                  value={filtro}
                  onChange={(e) => setFiltro(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Usuário</th>
              <th>Privilégios</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {usuariosFiltrados.map((usuario) => (
              <tr
                key={usuario.id}
                onClick={() => handleMostrarSenha(usuario.id)}
                className={usuario.blocked ? "blocked-user-row" : ""} // Adiciona classe condicional
              >
                <td>{usuario.fullName}</td>
                <td>{usuario.cpf}</td>
                <td>{usuario.username}</td>
                <td>{getPrivilegios(usuario.userType)}</td>
                <td>
                  <div className="botoes-icones">
                    <button
                      onClick={() => handleEditarBloqueioUsuario(usuario)}
                      className={`action-button ${
                        usuario.blocked ? "unblock-button" : "block-button"
                      }`}
                      title={
                        usuario.blocked
                          ? "Desbloquear Usuário"
                          : "Bloquear Usuário"
                      }
                    >
                      {usuario.blocked ? (
                        <i className="fas fa-lock-open"></i> // Ícone de desbloqueio
                      ) : (
                        <i className="fas fa-ban"></i> // Ícone de bloqueio
                      )}
                    </button>
                    <button
                      onClick={() => handleEditarUsuario(usuario)}
                      className="action-button edit-button"
                      title="Editar"
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                    <button
                      onClick={() => handleExcluirUsuario(usuario)}
                      className="action-button delete-button"
                      title="Excluir"
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ModalCriarUsuario
        isOpen={modalCriarUsuario}
        onClose={() => setModalCriarUsuario(false)}
        atualizar={atualizar}
        setAtualizar={setAtualizar}
        setUsuarioEditar={setUsuarioSelecionado}
        usuarioEditar={usuarioSelecionado}
      />
      <ModalEditarUsuario
        isOpen={modalEditarUsuario}
        onClose={() => setModalEditarUsuario(false)}
        atualizar={atualizar}
        setAtualizar={setAtualizar}
        usuarioSelecionado={usuarioSelecionado}
      />
    </div>
  );
};

export default GestaoUsuarios;
